import Vue from 'vue';
import Router from 'vue-router';
import {getCookie} from '@/cookie.js';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    //首页
    {
      path: '/',
      redirect:'/index',
      // name: 'home',
      // component: () => import('@/components/home/index.vue')
    },
    {
      path: '/index',
      component: () => import('@/components/home/index.vue')
    },
    {
      path: '/recome',
      component: () => import('@/components/common/recome.vue'),
    },
    {
      path: '/recomeSearch',
      component: () => import('@/components/common/recomeSearch.vue'),
    },
    {
      path: '/export',
      component: () => import('@/components/common/export.vue'),
    },
    {
      path: '/remind',
      component: () => import('@/components/common/remind.vue'),
    },
    {
      path: '/comfire',
      component: () => import('@/components/common/comfire.vue'),
    },
	{
	  path: '/monitoring',
	  component: () => import('@/components/common/monitoring.vue'),
	},
    {
      path: '/like',
      component: () => import('@/components/common/like.vue')
    },
    {
      path: '/brandWarn',
      component: () => import('@/components/common/brandWarn.vue')
    },
    {
      path: '/famous',
      component: () => import('@/components/common/famous.vue')
    },
    {
      path: '/brandThing',
      component: () => import('@/components/common/brandThing.vue')
    },
    {
      path: '/cate',
      component: () => import('@/components/common/cate.vue')
    },
    {
      path: '/applycate',
      component: () => import('@/components/common/applycate.vue')
    },
    {
      path: '/search',
      component: () => import('@/components/common/search.vue')
    },
    // main
    {
      path: '/Layout',
      component: () => import('@/components/layout/Layout'),
      children: [
        // 新 知产管理
        {
          path: '/intellectualProperty',
          component: () => import('@/components/intellectualProperty/intellectualProperty')
        },
        // 新 知产管理 - 公司商标详情
        {
          path: '/intellectualProperty/:name',
          component: () => import('@/components/intellectualProperty/intellectualProperty')
        },
        // 商标列表
        {
          path: '/intellectualProperty/brandList/:id',
          component: () => import('@/components/intellectualProperty/brandList')
        },
        // 公司 - 商标列表
        {
          path: '/intellectualProperty/companyBrandList/:id',
          component: () => import('@/components/intellectualProperty/companyBrandList')
        },
        // 商标详情
        {
          path: '/brandDetail',
          component: () => import('@/components/intellectualProperty/brandDetail')
        },
      ]
    }
  ]
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});


router.beforeEach((to, from, next) => {
  let isLogin = getCookie('u_id');
  if (to.meta.needLogin && !isLogin) {
    next({path: '/login', query: {redirect: to.fullPath}})
  } else {
    next()
  }
})

export default router;
